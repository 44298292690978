import React from "react"
import { Link } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import tamm from "../../../images/viilu_tammi.jpg"
import pook from "../../../images/viilu_pyokki.jpg"
import saar from "../../../images/viilu_saarni.jpg"
import mand from "../../../images/viilu_manty.jpg"
import mahagon from "../../../images/viilu_mahonki.jpg"
import wenge from "../../../images/wenge2.jpg"
import kask from "../../../images/viilu_koivu.jpg"
import kirss from "../../../images/kirsikka2.jpg"
import pahkel from "../../../images/pahkina.jpg"

import valge from "../../../images/mel_valkosaarni.jpg"
import valge2 from "../../../images/mel_valkoinen.jpg"
import tamm2 from "../../../images/mel_tammi.jpg"
import pahkel2 from "../../../images/mel_pahkina2.jpg"
import pook2 from "../../../images/mel_pyokki.jpg"
import kirss2 from "../../../images/mel_kirsikka.jpg"
import hobehall from "../../../images/mel_hopea2.jpg"

import teema1 from "../../../images/teema_t.jpg"
import teema2 from "../../../images/teema_l.jpg"
import teema3 from "../../../images/teema_ki.jpg"
import teema4 from "../../../images/teema_k.jpg"
import teema5 from "../../../images/puupeili.jpg"
import teema6 from "../../../images/polystyreeni.jpg"
import teema7 from "../../../images/melamiinipeili.jpg"
import teema8 from "../../../images/katedraalilasi.jpg"
import teema9 from "../../../images/fasettilasi.jpg"

const puidujaKlaasiValik = () => (
  <Layout>
    <SEO title="Voldikuksed, puidu ja klaasi valik" />

    <div className="header-bottom">
      <div className="bg-color-bottom">
        <div className="header-bottom-text">
          <div className="intro-para text-center quote">
            <h1>VOLDIKUKSED</h1>
            <hr className="footer-line"></hr>
          </div>
        </div>
      </div>
    </div>

    <div
      className="epoporandad"
      style={{
        maxWidth: 1300,
        margin: "auto",
      }}
    >
      <div className="wrapper-epo">
        <Link
          to="/ruumijagajad/voldikuksed/"
          activeStyle={{ borderBottom: "3px solid #ffa609" }}
          partiallyActive={true}
        >
          <div className="epo">
            <h2>Voldikuksed</h2>
          </div>
        </Link>
        <Link to="/ruumijagajad/lootsuksed/">
          <div className="epo">
            <h2>Lõõtsuksed</h2>
          </div>
        </Link>
        <Link to="/ruumijagajad/siirdeseinad/">
          <div className="epo">
            <h2>Siirdeseinad</h2>
          </div>
        </Link>
        <Link to="/ruumijagajad/voldikseinad/">
          <div className="epo">
            <h2>Voldikseinad</h2>
          </div>
        </Link>
        <Link to="/ruumijagajad/referentsid/">
          <div className="epo">
            <h2>Referentsid</h2>
          </div>
        </Link>
      </div>

      <div className="naturaalsed-spoonid-wrapper">
        <h1>Naturaalsed spoonid</h1>
        <div className="spoonivalik-wrapper">
          <img src={tamm} alt="Tamm" width="100"></img>
          <img src={pook} alt="Pöök" width="100"></img>
          <img src={saar} alt="Saar" width="100"></img>
          <img src={mand} alt="Mänd" width="100"></img>
          <img src={mahagon} alt="Mahagon" width="100"></img>
          <img src={wenge} alt="Wenge" width="100"></img>
          <img src={kask} alt="Kask" width="100"></img>
          <img src={kirss} alt="Kirss" width="100"></img>
          <img src={pahkel} alt="Pähkel" width="100"></img>
        </div>
      </div>
    </div>
    <div className="materjalid-grid">
      <div className="materjalid-wrapper">
        <h1>Melamiinid </h1>
        <div className="materjalid-inner-wrapper">
          <img src={valge} alt="Tamm" width="100"></img>
          <img src={valge2} alt="Tamm" width="100"></img>
          <img src={tamm2} alt="Tamm" width="100"></img>
          <img src={pahkel2} alt="Tamm" width="100"></img>
          <img src={pook2} alt="Tamm" width="100"></img>
          <img src={kirss2} alt="Tamm" width="100"></img>
          <img src={hobehall} alt="Tamm" width="100"></img>
        </div>
      </div>
      <div className="materjalid-wrapper">
        <h1>Klaasid ja puitaknad </h1>
        <div className="materjalid-inner-wrapper-2">
          <img src={teema1} alt="Kaminaruum" width="50"></img>
          <img src={teema2} alt="Lastetuba" width="50"></img>
          <img src={teema3} alt="Tähed" width="50"></img>
          <img src={teema4} alt="Köök" width="50"></img>
          <img src={teema5} alt="Puitaken" width="50"></img>
          <img src={teema6} alt="Polüstüreen" width="50"></img>
          <img src={teema7} alt="Melamiinaken" width="50"></img>
          <img src={teema8} alt="Katedraal" width="50"></img>
          <img src={teema9} alt="Fasettklaas" width="50"></img>
        </div>
      </div>
    </div>

    <div className="varvitud-pinnad">
      <h1>Värvitud pinnad </h1>
      <ul>
        <li>Standartne valge NCS S 0502Y</li>
        <li>Muud värvitoonid nt RAL, NCS, Symphony kataloogide alusel</li>
      </ul>
    </div>
  </Layout>
)

export default puidujaKlaasiValik
